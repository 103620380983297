"use client";

import { DialogContextProvider } from "@/app/_components/share/Dialog";
import { ModalContextProvider } from "@/app/_components/share/Modal";
import { GoogleAppId } from "@/services/constants/global";
import "@/services/translations/i18n";
import "@/styles/global.css";
import theme from "@/styles/theme";
import { CacheProvider } from "@chakra-ui/next-js";
import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import "swiper/css";
import "swiper/css/bundle";
ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID);
/**
 * Hide console logs in production
 */
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, keepPreviousData: true, retry: 0 },
  },
});

const { ToastContainer, toast } = createStandaloneToast();

export { toast };

export function Providers({ children }) {
  return (
    <CacheProvider>
      <RecoilRoot>
        <RecoilNexus />
        <CacheProvider>
          <GoogleOAuthProvider clientId={GoogleAppId}>
            <ChakraProvider theme={theme} cssVarsRoot="body">
              <QueryClientProvider client={queryClient}>
                <ModalContextProvider>
                  <DialogContextProvider>{children}</DialogContextProvider>
                </ModalContextProvider>
                <ToastContainer />
              </QueryClientProvider>
            </ChakraProvider>
          </GoogleOAuthProvider>
        </CacheProvider>
      </RecoilRoot>
    </CacheProvider>
  );
}
